import React from 'react';
import whatsapp from '../static/images/whatsapp.svg';
import viber from '../static/images/viber.svg';
import telegram from '../static/images/telegram.svg';
import mail from '../static/images/mail.svg';
import phone_numbers from '../static/images/phone_numbers.svg';

const AboutUs = () => {
  return (
    <div id="aboutus" className="about_us_section">
      <div className="container about_container">
        <h2>About Us</h2>
        <p>We are the Center of Advanced Software Technologies (CAST) based in Armenia. We present our latest Automatic
          Speech Recognition (ASR) tool – ArmAViR (Armenian Automatic Voice Recognition) which is designed to transcribe
          spoken Armenian language into text. Dynamically, once every little while we release a new enhanced version of
          the tool.<br/><br/>
          The tool is also accessible using API. To have the access to the API please contact us.
        </p>
        <div className="media_phones_container">
          <div className="media">
            <img src={whatsapp} alt="whatsapp"/>
            <img src={viber} alt="viber"/>
            <img src={telegram} alt="telegram"/>
          </div>
          <div className="phone_number">
            <img src={phone_numbers} alt="phone numbers" className="phone_numbers"/>
          </div>
        </div>
        <div className="mail_container">
          <img src={mail} alt="mail"/>
          <a href="mailto:info@castech.am" className="mail">info@castech.am</a>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;