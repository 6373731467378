import React, {useState, useEffect, useRef} from 'react';
import RecordRTC from 'recordrtc';
import axios from "axios";
import microphoneIcon from "../static/images/microphone.svg";

// Uncomment for online transcriber
// const WS_URL = process.env.REACT_APP_WS_URL;
const API_URL = process.env.REACT_APP_API_URL;
const RECORD_LIMIT_MIN = Number(process.env.REACT_APP_RECORD_LIMIT_MIN);
const RECORD_LIMIT_MS = RECORD_LIMIT_MIN * 1000 * 60;
const TIME_SLICE = 1000;
const SAMPLE_RATE = 16000;


const RecordAudio = ({setAudioPath, setStatusTitle, setTranscription, setURL, setIsRecording, model_id, token}) => {
  // Uncomment for online transcriber
  // const socketRef = useRef(null);
  const recorderRef = useRef(null);
  const [recording, setRecording] = useState(false);
  // Uncomment for online transcriber
  // const initializeSocket = () => {
  //   const newSocket = new WebSocket(WS_URL);

  //   newSocket.onopen = () => {
  //     console.log('WebSocket connection established');
  //     socketRef.current = newSocket;
  //     getPermission();
  //   };

  //   newSocket.onmessage = (event) => {
  //     const obj = JSON.parse(event.data);
  //     console.log('Received message.');
  //     setTranscription(obj.transcription);
  //     setAudioPath(obj.path);
  //   };

  //   newSocket.onclose = (event) => {
  //     console.log('Socket is closed.');
  //     setStatusTitle('Ready');
  //     setRecording(false);
  //   };

  //   newSocket.onerror = (event) => {
  //     console.log('Socket error.');
  //     alert('Error occurred while processing audio.');
  //     if (recording) {
  //       setStatusTitle('Ready');
  //       setRecording(false);
  //     }
  //     else {
  //       setStatusTitle('Initial');
  //     }
  //   }
  // };
  useEffect(() => {
    setIsRecording(recording);
  }, [recording, setIsRecording]);

  useEffect(() => {
    setAudioPath(null);
  }, [
    // Uncomment for online transcriber
    // socketRef,
    setAudioPath
  ]);

  const getPermission = () => {
    navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    })
      .then(stream => startRecording(stream))
      .catch((error) => {
        alert('You have no permission to record audio.');
      })
  }

  const startRecording = (stream) => {
    const date = new Date();
    const start_time = date.getTime();
    setRecording(true);
    setAudioPath(null);
    setURL(null);

    // Uncomment this part of code and comment other part of method for online transcriber
    // if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
    //   setRecording(true);
    //   setTranscription(null);
    //   setStatusTitle('InProcess');

    //   recorderRef.current = RecordRTC(stream, {
    //     recorderType: RecordRTC.StereoAudioRecorder,
    //     type: 'audio',
    //     timeSlice: TIME_SLICE,
    //     numberOfAudioChannels: 1,
    //     desiredSampRate: SAMPLE_RATE,
    //     ondataavailable: blob => {
    //       const date = new Date();
    //       if ((date.getTime() - start_time) <= RECORD_LIMIT_MS ) {
    //         if (blob.size > 0 && socketRef.current.readyState === 1) {
    //           console.log(blob);
    //           socketRef.current.send(blob);
    //         }
    //       }
    //       else {
    //         setRecording(false);
    //         recorderRef.current.stopRecording();
    //         alert(`You cannot record more than ${RECORD_LIMIT_MIN} minutes.`);
    //       }
    //   });
    //   recorderRef.current.startRecording();
    // } else {
    //   initializeSocket();
    // }

    setRecording(true);
    setTranscription(null);
    setStatusTitle('InProcess');

    recorderRef.current = RecordRTC(stream, {
      recorderType: RecordRTC.StereoAudioRecorder,
      type: 'audio',
      disableLogs: true,
      timeSlice: TIME_SLICE,
      numberOfAudioChannels: 1,
      desiredSampRate: SAMPLE_RATE,
      ondataavailable: () => {
        const date = new Date();
        if ((date.getTime() - start_time) > RECORD_LIMIT_MS) {
          setRecording(false);
          recorderRef.current.stopRecording();
          alert(`You cannot record more than ${RECORD_LIMIT_MIN} minutes.`);
        }
      }
    });
    recorderRef.current.startRecording();
  }

  const stopRecording = () => {
    if (recorderRef.current) {
      recorderRef.current.stopRecording(function () {
        const record = recorderRef.current.getBlob();
        const url = URL.createObjectURL(record);
        setURL(url);
        // Comment code below to the comment sign for online transcriber
        const formData = new FormData();
        formData.append('file', record);
        formData.append('model_id', model_id);
        const API_PATH = token ? '/api/transcribe_audio' : '/upload_audio'
        const HEADERS = {
          "Content-Type": "multipart/form-data",

        }
        if (token){
          HEADERS['X-access-token'] = token;
        }
        axios.post(API_URL + API_PATH, formData, {
          headers: HEADERS,
        })
          .then((result) => {
            const data = result.data;
            setStatusTitle('Ready');
            setTranscription(data.transcription);
            setAudioPath(data.path);
          })
          .catch((error) => {
            let message = 'Error occurred while processing recording.';
            try {
              if (error?.response.status === 415 || error?.response.status === 422|| error?.response.status === 429) {
                message = error.response.data.detail;
              }
            } catch (e) {
              message = 'Error occurred while connecting to server.'
            }
            setStatusTitle('Initial');
            alert(message);
          });
        // end of comment
      });
      setRecording(false);
    }
  }

  return (
    <div className="main_icon_block">
      <div className={`main_icon ${recording ? 'active_main_icon' : ''}`}>
        <button id="speech" onClick={!recording ? getPermission : stopRecording}>
          {recording && <div className="pulse-ring"></div>}
          <img src={microphoneIcon} alt="microphone"/>
        </button>
        {!recording ? <p className='audio_text audio_text_record'>Tap to record</p> :
          <p className='rec_title'>Tap to finish recording</p>}
      </div>
    </div>
  )
}

export default RecordAudio;