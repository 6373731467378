import React, {useState, useCallback, useEffect, useRef} from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import axios from "axios";
import {useDropzone} from 'react-dropzone';
import uploadIcon from "../static/images/upload.svg";

const API_URL = process.env.REACT_APP_API_URL;
const UPLOAD_LIMIT_MB = Number(process.env.REACT_APP_UPLOAD_LIMIT_MB);
const SUPPORTED_FORMATS = process.env.REACT_APP_SUPPORTED_FORMATS.split(', ');
const ACCEPTED_FORMATS = process.env.REACT_APP_ACCEPTED_FORMATS;

const UploadAudio = ({setAudioPath, setStatusTitle, setTranscription, setURL, setProgress, isRecording, model_id, authToken }) => {
  let [isActive, setIsActive] = useState(false);
  let model = useRef(model_id);
  let token = useRef(authToken);

  useEffect(() => {
    model.current = model_id;
  }, [model_id]);

  useEffect(() => {
    token.current = authToken;
  }, [authToken]);

  const handleClick = (event) => {
    if (isRecording) {
      event.preventDefault();
      event.stopPropagation();
    }
    setIsActive(true);
    setTimeout(() => {
      setIsActive(false);
    }, 1000);
  };
  const isFormatSupported = (filename) => {
    const format = filename.split('.').pop();
    return SUPPORTED_FORMATS.includes(format);
  }

  const uploadFile = (file) => {
    setAudioPath(null);
    if (file.size > UPLOAD_LIMIT_MB * 1024 * 1024) {
      alert(`File size must be ${UPLOAD_LIMIT_MB} MB or less.`);
      return;
    }
    if (!isFormatSupported(file.name)) {
      alert(`.${file.name.split('.').pop()} file format is not supported. \nPlease select a file with one of the supported formats:\n${SUPPORTED_FORMATS}.`);
      return;
    }
    setTranscription(null);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('model_id', model.current);
    const API_PATH = token.current ? '/api/transcribe_audio' : '/upload_audio'
    const HEADERS = {
      "Content-Type": "multipart/form-data",
//      "accept": "application/json"
    }
    if (token.current){
      HEADERS['X-access-token'] = token.current;
    }

    axios.post(API_URL + API_PATH, formData, {
      headers: HEADERS,
      onUploadProgress: (progressEvent) => {
        setStatusTitle('Loading');
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setProgress(progress);
        if (progress === 100) {
          setStatusTitle('InProcess');
        }
      }
    })
      .then((result) => {
        const data = result.data;
        setStatusTitle('Ready');
        setTranscription(data.transcription);
        setAudioPath(data.path);
        const url = URL.createObjectURL(file);
        setURL(url);
      })
      .catch((error) => {
        let message = 'Error occurred while processing file.';
        try {
          if (error?.response.status === 415 || error?.response.status === 422 || error?.response.status === 429) {
            message = error.response.data.detail;
          }
        } catch (e) {
          message = 'Error occurred while connecting to server.'
        }
        setStatusTitle('Initial');
        alert(message);
      });
  }

  const onDrop = useCallback((files) => {
    if (files.length) {
      uploadFile(files[0]);
    } else {
      alert('No file found.');
    }
  }, []);

  const onFileAdded = (event) => {
    if (isRecording) {
      event.preventDefault();
      event.stopPropagation();
    }
    const file = event.target.files[0];
    if (file) {
      event.target.value = null;
      uploadFile(file);
    } else {
      alert('No file found.');
    }
  }

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  return (
    <div {...getRootProps()}>
      <div className={`main_icon ${isActive ? 'active_main_icon' : ''}  ${isRecording ? 'upload_disabled' : ''}`}
           onClick={handleClick}>
        <input
          className="FileInput"
          type="file"
          onChange={() => onFileAdded()}
          accept={ACCEPTED_FORMATS}
          disabled={!!isRecording}
          {...getInputProps()}
        />
        <img src={uploadIcon} alt="upload" className={`${isRecording ? 'upload_disabled' : ''}`}/>
        <p className='audio_text audio_text_upload'>Drag'&'Drop some files here or click to upload</p>
      </div>
    </div>
  );
}

export default UploadAudio;