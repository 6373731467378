import React from 'react';
import Navigation from "./components/Navigation";
import Main from "./components/Main"
import API from "./components/API";
import AboutUs from "./components/AboutUs";
import Footer from "./components/Footer";
import './static/styles/style.css';
import 'reactjs-popup/dist/index.css';

function App() {
  return (
    <div className="App">
      <Navigation/>
      <Main/>
      <API/>
      <AboutUs/>
      <Footer/>
    </div>
  );
}

export default App;
