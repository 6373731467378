import React from 'react';

class TableModels extends React.Component {
  render() {
    return (
      <div className="table_ex">
        <h3 className="model_heading">Common Voice</h3>
        <table>
          <thead className="titles">
          <tr>
            <th>Existing Tools</th>
            <th>Word Error Rate %</th>
          </tr>
          </thead>
          <tbody className="model_td">
          <tr>
            <td><a href="https://huggingface.co/arampacha/whisper-large-hy-2" target="_blank">arampacha</a></td>
            <td>18.1%</td>
          </tr>
          <tr>
            <td><a href=" https://huggingface.co/YSU/aspram" target="_blank">Aspram</a></td>
            <td>12.5%</td>
          </tr>
          <tr>
            <td><a href="https://github.com/facebookresearch/seamless_communication/tree/main/demo/m4tv2" target="_blank">SeamlessM4T</a></td>
            <td>10.5%</td>
          </tr>
          <tr>
            <td className="armavir_td">Armavir</td>
            <td>7.5%</td>
          </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default TableModels;