import React from 'react';
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import file_copy from "../static/images/file-copy_white.svg"

const TableExample = () => {
  const copyToClipBoard = async (event) => {
    try {
      const targetCodeElement = event.currentTarget.closest(".code");
      await navigator.clipboard.writeText(targetCodeElement.innerText);
    } catch (error) {
      console.log(error);
      alert('Error occurred while trying to copy text.')
    }
  };
  return (
    <div className="table_ex">
      <div className="first_section">
        <h2>Obtaining API Permissions</h2>
        <p>To gain permission for sending API requests, please contact our administrators.</p>
        <h2>Getting Started</h2>
        <p>Once you have acquired an access token, you can use the following scripts to interact with the Armavir ASR
          API.</p>
        <p><span className="note">Note:</span> Armavir supports audio transcription for the following formats:
          <span className="white_bold"> wav, flac, mp3, ogg.</span></p>
      </div>
      <div className="second_section">
        <h2>cURL Requests</h2>
        <p className="white_bold">1. Check Access Token Balance (audio seconds that can be transcribed using the
          provided access token):</p>
        <div className="code">
          <code>
            curl -X 'GET' \ <br/>
            <span className="padd_left"></span>'https://voice-cast.am/api/check_balance' \ <br/>
            <span className="padd_left">-H</span> 'accept: <span className="red_code">application/json' \</span> <br/>
            <span className="padd_left">-H</span> "X-access-token: <span
            className="red_code"> &lt;access_token&gt;"</span> <br/>
          </code>
          <div className="copy_btn">
            <Tooltip title="Copy">
              <IconButton aria-label="copy" onClick={(event) => copyToClipBoard(event)}>
                <img src={file_copy} alt="file_copy"/>
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <p>or</p>
        <div className="code">
          <code>
            curl -X 'GET' \ <br/>
            <span
              className="padd_left"></span>'https://voice-cast.am/api/check_balance?access-token=&lt;access_token&gt;'
            \ <br/>
            <span className="padd_left">-H</span> 'accept:<span className="red_code"> application/json' </span><br/>
          </code>
          <div className="copy_btn">
            <IconButton aria-label="copy" onClick={(event) => copyToClipBoard(event)}>
              <Tooltip title="Copy">
                <img src={file_copy} alt="file_copy"/>
              </Tooltip>
            </IconButton>
          </div>
        </div>
        <p className="white_bold">2. Transcribe Audio:</p>
        <span className="note padd_left">Note:</span>
        <p className="white_bold">&ensp; 1. max allowed audio duration 480 seconds</p>
        <p className="white_bold">&ensp; 2. max allowed audio size 10</p>
        <div className="code">
          <code>
            curl -X POST \ <br/>
            <span className="padd_left"></span>"https://voice-cast.am/api/transcribe_audio" \ <br/>
            <span className="padd_left">-H</span> 'accept:<span className="red_code"> application/json' \ </span><br/>
            <span className="padd_left">-H</span> "X-access-token:<span
            className="red_code"> &lt;access_token&gt;" \ </span><br/>
            <span className="padd_left">-F</span> "file=@&lt;audio_file_path&gt;" <br/>
          </code>
          <div className="copy_btn">
            <IconButton aria-label="copy" onClick={(event) => copyToClipBoard(event)}>
              <Tooltip title="Copy">
                <img src={file_copy} alt="file_copy"/>
              </Tooltip>
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TableExample;
