import React, {useState, useEffect} from 'react';
import armavirImg from "../static/images/Armavir.png";
import mainVector from "../static/images/Vector_white.png";
import Transcription from "./Transcription";
import AudioBlock from "./Audio";
import ReactPlayer from 'react-player';
import StarRating from './StarRating';
import AudioTemplates from './AudioTemplates';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import TokenImg from "../static/images/token.svg";
import Settings from './Settings';
import axios from "axios";

//  const API_URL = 'http://localhost:8000';
// TODO: get API_URL from env
const API_URL = 'https://voice-cast.am';

const Main = () => {
  let [path, setAudioPath] = useState(null);
  let [transcription, setTranscription] = useState(null);
  let [progress, setProgress] = useState(0);
  let [statusTitle, setStatusTitle] = useState('Initial');
  let [blobURL, setURL] = useState(null);
  let [model_id, setModelID] = useState(1);
  let [authToken, setAuthToken] = useState('');

  let [expanded, setExpanded] = useState(false);
  const [audioTemplatesVisible, setAudioTemplatesVisible] = useState(false);
  const [showAudioTemplate, setShowAudioTemplate] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const toggleAudioTemplate = () => {
    setAudioTemplatesVisible(!audioTemplatesVisible);
    setShowAudioTemplate(!showAudioTemplate);
    setShowOptions(true)
  };

  useEffect(() => {
    setAudioPath(path);
  }, [path]);

  useEffect(() => {
    setModelID(model_id);
  }, [model_id]);

  const setModel = (model) => {
    setModelID(model);
    setTranscription(null);
    setAudioPath(null);
    setURL(null);
    setStatusTitle('Initial');
  }

  const setToken = (token) => {
    setAuthToken(token);
  }

  const setTemplate = async (template) => {
    setTranscription(null);
    setAudioPath(null);
    let name = `Sample${template}.wav`;
    let path = `/audio/${name}`;
    setURL(path);
    const formData = new FormData();
    const response = await fetch(path);
    const blob = await response.blob();

    formData.append('file', new File([blob], name));
    formData.append('model_id', model_id);
    const API_PATH = authToken ? '/api/transcribe_audio' : '/upload_audio'
    const HEADERS = {
      "Content-Type": "multipart/form-data",
    }
    if (authToken){
      HEADERS['X-access-token'] = authToken;
    }
    axios.post(API_URL + API_PATH, formData, {
      headers: HEADERS,
      onUploadProgress: (progressEvent) => {
        setStatusTitle('Loading');
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setProgress(progress);
        if (progress === 100) {
          setStatusTitle('InProcess');
        }
      }
    })
      .then((result) => {
        const data = result.data;
        setStatusTitle('Ready');
        setTranscription(data.transcription);
        setAudioPath(data.path);
      })
      .catch((error) => {
        let message = 'Error occurred while processing file.';
        try {
          if (error?.response.status === 415 || error?.response.status === 422 || error?.response.status === 429) {
            message = error.response.data.detail;
          }
        } catch (e) {
          message = 'Error occurred while connecting to server.'
        }
        setStatusTitle('Initial');
        alert(message);
      });
  };

  const resetStatusTitle = () => {
    setStatusTitle('Initial');
  };


  return (
    <main id="speechrecognition" className="main">
      <div className="main_content">
        <div>
          <h2>ArmAViR</h2>
          <img src={armavirImg} alt="Armavir_main_img"/>
          <h3>Armenian Automatic <br/> Voice Recognition</h3>
          <p className="description">The tool is now in an alpha version</p>
        </div>
        <div>
          <AudioBlock setAudioPath={setAudioPath} setStatusTitle={setStatusTitle}
                      setTranscription={setTranscription} setProgress={setProgress}
                      setURL={setURL} model_id={model_id} token={authToken}
          />
          <div className="audio">
            {blobURL ? (
              <ReactPlayer url={blobURL} controls={true}/>
            ) : (
              <audio src="" controls/>
            )}
          </div>
          <p class="token_title">Enter your access token. Need one?&nbsp;<a href="#aboutus">Contact us</a>&nbsp;for help.</p>
          <div class="token_field">
            <TextField
              label="Access Token"
              type="password"
              slotProps={{
                input: {
                  startAdornment: (
                  <InputAdornment position='start'>
                    <img src={TokenImg} alt="token" />
                  </InputAdornment>
                  ),
                },
              }}
              value={authToken}
              onChange={(event) => {
                  setAuthToken(event.target.value);
                }}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="textarea_container">
            <Transcription statusTitle={statusTitle} transcription={transcription} progress={progress}/>
            <AudioTemplates setTemplate={setTemplate} resetStatusTitle={resetStatusTitle}/>
            <Settings setModel={setModel} model_id={model_id} />
          </div>
          {path ? <StarRating path={path}/> : <div className="empty_div">&nbsp;</div>}
          <div className="main_vector">
            <img src={mainVector} alt="white_vector"/>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Main;