import React from 'react';
import arrow_up from "../static/images/arrow-up.svg";
import settings from "../static/images/settings.svg"


const API_URL = process.env.REACT_APP_API_URL;

class Settings extends React.Component {
  constructor() {
    super();
    this.handleOptionClick = this.handleOptionClick.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.toggleSettings = this.toggleSettings.bind(this);
    this.fetchModels = this.fetchModels.bind(this);
    this.state = {
      isOpen: false,
      selectedOption: null,
      isSettingsVisible: false,
      models: [],
    };
  }

  componentDidMount() {
    this.fetchModels();
  }

  fetchModels () {
    fetch(API_URL + '/get_models_info', {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => response.json())
        .then((result) => {
            this.setState({
              models: result.models_info,
              selectedOption: {value: this.props.model_id, label: `Model ${result.models_info[this.props.model_id - 1].name}`},
            })
        })
        .catch((error) => {
            console.log(error);
            alert('Error occurred while processing file.');
        });
  }

  toggleSettings() {
    this.setState((prevState) => ({
      isSettingsVisible: !prevState.isSettingsVisible,
      toggleDropdown: !prevState.toggleDropdown,
      isOpen: false,
    }));
  }

  toggleDropdown() {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  }

  handleOptionClick(index) {
    this.setState({
      selectedOption: {value: index, label: `Model ${this.state.models[index - 1].name}`},
      isOpen: false,
    });
    this.props.setModel(index);
  }

  render() {
    return (
      <div
        className={this.state.isSettingsVisible && !this.state.isOpen ? 'block_without_options' : this.state.isOpen ? 'select models' : 'only_icon'}
      >
        <div className="settings">
          {this.state.isSettingsVisible && (
            <div className="selected-option">
              <img
                src={arrow_up}
                alt="arrow_up"
                className={`arrow_up ${this.state.isOpen ? 'open' : ''}`}
                onClick={() => {
                  this.toggleDropdown();
                }}
              />
              <p className="audio_temp_text">
                {this.state.selectedOption ? this.state.selectedOption.label : 'Models'}
              </p>
            </div>
          )}
          <img
            src={settings} alt="setting_models" onClick={this.toggleSettings}
            className={`person_voice ${!this.state.isOpen && !this.state.isSettingsVisible ? 'setting_models' : ''}`}
          />
        </div>
        {this.state.isOpen && (
          <ul className="options">
            {this.state.models.map((model, i) => (
              <li key={i} onClick={() => this.handleOptionClick(i + 1)}>
                Model {model.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default Settings;