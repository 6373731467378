import React, {useState, useEffect} from "react";
import {IoMdStar} from "react-icons/io";


const API_URL = process.env.REACT_APP_API_URL;


const StarRating = (props) => {
  const [path, setPath] = useState(null);
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);

  useEffect(() => {
    setPath(props.path);
    setRating(null);
  }, [props]);

  const onRatingClick = (ratingValue) => {
    const params = {
      'path': path,
      'rate': ratingValue
    }
    fetch(API_URL + '/update_rate', {
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(params)
    })
      .then((response) => response.json())
      .then((data) => {
        setRating(data.rate);
      })
      .catch((error) => {
        alert('Error occurred while rating audio transcription. Please try later.')
        console.log(error);
      });
  }

  return (
    <div className="star_rating">
      <p className="rate">Please, rate the quality of the transcriber</p>
      <div>
        {[...Array(5)].map((star, i) => {
          const ratingValue = i + 1;

          return (
            <label key={i}>
              <input
                type="radio"
                name="rating"
                value={ratingValue}
                onClick={() => onRatingClick(ratingValue)}
              />
              <IoMdStar
                key={i}
                className="star"
                size={66}
                color={ratingValue <= (hover || rating) ? "#FF9518" : "#C8C8C8"}
                onMouseEnter={() => setHover(ratingValue)}
                onMouseLeave={() => setHover(null)}
              />
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default StarRating;