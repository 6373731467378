import React, {useState, useEffect} from 'react';
import logoImg from "../static/images/Logo.png";

const Navigation = () => {
  const NavItems = ["Speech Recognition", "API", "About Us"];
  const [activeItem, setActiveItem] = useState(null);

  const scrollTo = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = NavItems.map(item => item.replace(/\s+/g, '').toLowerCase());
      const scrollPosition = window.scrollY + window.innerHeight / 2;

      for (let i = sections.length - 1; i >= 0; i--) {
        const section = document.getElementById(sections[i]);
        if (section && section.offsetTop <= scrollPosition) {
          setActiveItem(sections[i]);
          break;
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
  }, []);

  // const handleItemClick = (itemId) => {
  //   setActiveItem(itemId);
  //   scrollTo(itemId);
  // };
  return (
    <header className="header">
      <div className="container">
        <div className="logo">
          <img src={logoImg} alt="Logo_Armavir"/>
        </div>
        <nav>
          <ul className="nav_items">
            {NavItems.map((item, index) => {
              const itemId = item.replace(/\s+/g, '').toLowerCase();
              return (
                <li key={index} onClick={() => {
                  setActiveItem(itemId);
                  scrollTo(itemId);
                }}>
                  <a
                    href={`#${itemId}`}
                    className={activeItem === itemId ? 'active_nav_item' : ''}
                  >
                    {item}
                  </a>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Navigation;