import React, { useState, useEffect } from 'react';
import copyright from "../static/images/c-circle.svg"


const Footer = () => {
  const [yearRange, setYearRange] = useState('');

  useEffect(() => {
    const currentYear = 2024;
    const nextYear = new Date().getFullYear();
    setYearRange(`${currentYear}${currentYear !== nextYear ? `-${nextYear}` : ''}`);
  }, []);

  return (
    <footer className="footer">
      <div className="copyrights">
        <div className="copyrights_icon">
          <img src={copyright} alt="copyright"/>
        </div>
        <div className="copyrights_text">
          <p>{yearRange}</p>
          <p>All Rights Reserved</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;