import React from 'react';
import arrow_up from "../static/images/arrow-up.svg";
import personVoice from "../static/images/person-voice.svg"

const audios = require.context('../../public/audio', true);
const options = audios.keys().map(audio => audios(audio));

class AudioTemplates extends React.Component {
  constructor() {
    super();
    this.handleOptionClick = this.handleOptionClick.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.toggleAudioTemplate = this.toggleAudioTemplate.bind(this);
    this.resetState = this.resetState.bind(this);
    this.state = {
      isOpen: false,
      selectedOption: null,
      isAudioTemplateVisible: false,
    };
  }

  toggleAudioTemplate() {
    this.setState((prevState) => ({
      isAudioTemplateVisible: !prevState.isAudioTemplateVisible,
      toggleDropdown: !prevState.toggleDropdown,
      isOpen: false,
    }));
  }

  toggleDropdown() {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  }

  handleOptionClick(index) {
    this.setState({
      selectedOption: {value: index, label: `Sample ${index}`},
      isOpen: false,
    });
    this.props.setTemplate(index);
  }

  resetState() {
    this.setState({
      selectedOption: null,
      isOpen: false,
      isAudioTemplateVisible: false,
    });
     this.props.resetStatusTitle();
  }

  render() {
    return (
      <div
        className={this.state.isAudioTemplateVisible && !this.state.isOpen ? 'block_without_options' : this.state.isOpen ? 'select' : 'only_icon'}
      >
        <div className="audio-template">
          {this.state.isAudioTemplateVisible && (
            <div className="selected-option">
              <img
                src={arrow_up}
                alt="arrow_up"
                className={`arrow_up ${this.state.isOpen ? 'open' : ''}`}
                onClick={() => {
                  this.toggleDropdown();
                }}
              />
              <p className="audio_temp_text">
                {this.state.selectedOption ? this.state.selectedOption.label : 'Audio Templates'}
              </p>
            </div>
          )}
          <img
            src={personVoice} alt="person_voice" onClick={this.toggleAudioTemplate}
            className={`person_voice ${!this.state.isOpen && !this.state.isAudioTemplateVisible ? 'inactive' : ''}`}
          />
        </div>
        {this.state.isOpen && (
          <ul className="options">
            <li className="init_opt" onClick={this.resetState}>Deselected</li>
            {options.map((option, i) => (
              <li key={i} onClick={() => this.handleOptionClick(i + 1)}>
                Sample {i + 1}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default AudioTemplates;