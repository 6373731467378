import React from 'react';
import TableEx from "./TableEx";
import TableModels from "./TableModels";

const API = () => {
  return (
    <div id="api" className="api_section">
      <div className="container">
        <div className="api_content">
          <div className="api_text">
            <h2>API</h2>
            <p>
              Welcome to the ASR API documentation. Our API enables developers to integrate state-of-the-art speech
              recognition capabilities into their applications, allowing for the conversion of audio speech into text
              with
              high accuracy. This document provides all the necessary information to get started with our API, including
              setup instructions, API endpoints, request/response formats, and example code.<br/>
              The ASR API provides an easy-to-use interface for transcribing spoken words into written text. It's
              designed
              for developers looking to add voice input features to their software, enhancing accessibility and user
              experience.
            </p>
          </div>
          <div className="tables_container">
            <TableEx/>
            <TableModels />
          </div>
        </div>
      </div>
    </div>
  );
};

export default API;