import React, {useState, useEffect} from 'react';
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from '@mui/joy/CircularProgress';
import IconButton from '@mui/material/IconButton';
import file_copy from "../static/images/file-copy.svg"
import 'font-awesome/css/font-awesome.min.css';

const Titles = {
  Loading: 'Loading file...',
  Initial: `Click on the "Settings" button to select a model. 
            Record or upload your voice to convert it to text. 
            Click on the "Speaker" button to select one of the samples and test the tool.`,
  InProcess: 'Transcribing...',
  Ready: 'Final transcription:',
}

const Transcription = ({statusTitle, transcription, progress, setTitleToInitial}) => {
  let [title, setTitle] = useState(Titles.Initial);
  const [open, setOpen] = useState(false);
  const [copyFeedback, setCopyFeedback] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setTitle(Titles[statusTitle]);
  }, [statusTitle]);

  const copyToClipBoard = async () => {
    try {
      const transcription = document.getElementById("transcription");
      await navigator.clipboard.writeText(transcription.innerText);
      setCopyFeedback("Copied");
      setOpen(true);
    } catch (error) {
      console.log(error);
      alert('Error occurred while trying to copy text.')
      setOpen(true);
    }
  };

  return (
    <div id="transcription" className="textarea">
      {title === Titles.Loading ?
        <Tooltip title={Titles.Loading} className="progress_container">
          <CircularProgress determinate variant="plain" color="red" className="progress" value={progress}/>
        </Tooltip> : null}
      {title === Titles.InProcess ?
        <Tooltip title={"Transcribing..."} className="progress_container">
          <CircularProgress variant="plain" className="progress"/>
        </Tooltip> : null}
      {title === Titles.Ready ?
        <div>
          <Tooltip
            open={open}
            onClose={handleClose}
            title={copyFeedback}
            leaveDelay={500}
          >
            <IconButton aria-label="copy" onClick={() => copyToClipBoard()}>
              <Tooltip title="Copy">
                <img src={file_copy} alt="file_copy"/>
              </Tooltip>
            </IconButton>
          </Tooltip>
        </div> : null}
      {title === Titles.Initial ?
        <p className='placeholder'>
          {Titles.Initial}
        </p>
        : transcription}
    </div>
  );
}

export default Transcription;